import React from 'react';
import Loading from "../../components/Loading";
import AppContext from "../../contexts/AppContext";
import routes from "../../routes";
import * as webviewUtils from "../../utils/webview";
import DownloadModal from "../../components/DownloadModal";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import i18n from "../../i18n";
import {fileToJson} from "../../utils/webview";
import * as api from "../../utils/api";
import ProcessingTab from "./ProcessingTab";

export default class ResultPage extends React.Component {

  state = {
    isLoading: true,
    file: null,
    previewImage: null,
  };

  componentDidMount() {
    logEvent(userEvents.PAGE_RESULT);

    if (this.props.location.state && this.props.location.state.file) {
      this.handleFileSelected(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  start = (fileUrl) => {
    this.setState({file: fileUrl});
  };

  handleDownloadFile = (fileUrl, logProps) => {
    // this.context.pushModal(<DownloadModal
    //   key="ResultPage-DownloadModal"
    //   fileUrl={fileUrl}
    //   onShare={(provider) => this.handleShare(provider, logProps)}
    // />);

    setTimeout(() => {
      if (window.clientConfig.isWebview) {
        webviewUtils.webviewShareDownload(fileUrl);
      } else {
        const url = new URL(window.appConfig.paths.api);
        url.pathname = "/api/download";
        url.searchParams.append("content_url", fileUrl);
        // window.open(url.toString(), "_blank");
        window.location.href = url.toString();
      }
    }, 100);
  };

  handleShare = (provider, logProps) => {
    logEvent(userEvents.TEMPLATE_SHARE, {provider, ...logProps});
  };

  handleFileSelected = (file) => {
    logEvent(userEvents.PHOTO_SELECT, {page: "result"});
    hitEvent(hits.PHOTO_SELECT);

    this.setState({isLoading: true});

    if (window.clientConfig.isWebview) {
      this.handleFileUploaded(fileToJson(file));
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => this.setState({previewImage: e.target.result});
    reader.readAsDataURL(file);

    api.createFile(file)
      .then(this.handleFileUploaded)
      .catch((error) => {
        this.props.history.replace(routes.ERROR, {
          message: error.message,
        });
      });
  };

  handleFileUploaded = (file) => {
    this.setState({
      file: file.url,
    }, () => {
      this.start(file.url);
    });
  }

  handleImageLoaded = () => {
    this.setState({isLoading: false});
  }

  render() {
    const tabProps = {
      key: this.state.file, // reset component on new files
      file: this.state.file,
      history: this.props.history,
      onDownloadFile: this.handleDownloadFile,
      onFileSelected: this.handleFileSelected,
      onImageLoaded: this.handleImageLoaded,
      hidden: this.state.isLoading,
    };

    return <React.Fragment>
      <Loading image={this.state.previewImage} hidden={!this.state.isLoading} />
      <main className="collage-page" hidden={this.state.isLoading}>
        {this.state.file && <ProcessingTab {...tabProps} />}
      </main>
    </React.Fragment>;
  }
}

ResultPage.contextType = AppContext;