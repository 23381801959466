import React from "react";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import {photolabTask} from "../../photolab/api";
import {assetUrl, debounce} from "../../utils/etc";
import i18n from "../../i18n";
import FileChooseButton from "../../components/FileChooseButton";
import ProcessingView from "../../components/ProcessingView";
import routes from "../../routes";

const initialProcessingState = {
  isProcessing: true,
  isProcessed: false,
  isFailed: false,
  result: null,
  error: null,
};

export default class ProcessingTab extends React.Component {

  state = {
    processing: {...initialProcessingState},
  };

  componentDidMount() {
    this.startProcessing();
  }

  startProcessing = () => {
    photolabTask(new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(6747))
      .addImage(new PhotolabTaskImageUrl(this.props.file))
      .setLanguage(window.clientConfig.lang || "en")
      .build())
      .then((taskResult) => {
        this.setState({
          processing: {
            ...initialProcessingState,
            isProcessing: false,
            isProcessed: true,
            result: taskResult,
          }
        });
      })
      .catch((err) => {
        this.setState({
          processing: {
            ...initialProcessingState,
            isProcessing: false,
            isFailed: true,
            error: err,
          }
        });
      });
  };

  handleRetryClick = () => debounce("ProcessingTab.handleRetryClick", 200, () => {
    // todo logic
  });

  handleDownloadStartClick = () => debounce("ProcessingTab.handleDownloadStartClick", 200, () => {
    this.props.onDownloadFile(this.state.processing.result.resultUrl);
  });

  handleBackButtonClick = () => {
    this.props.history.replace(routes.INDEX);
  };

  render() {
    return <div className="tab-content" hidden={this.props.hidden}>
      <div className="collage-container">
        <div className="container">
          {/* <h3 
            className="step"
            dangerouslySetInnerHTML={{__html: i18n.t("collage__step_1")}}
          />*/}

          <ProcessingView
            processing={this.state.processing}
            onImageLoaded={() => {
              this.props.onImageLoaded && this.props.onImageLoaded();
            }}
            onRetryClick={() => this.handleRetryClick()}
            onFileSelected={this.props.onFileSelected}
          />
        </div>
      </div>

      <div className={"footer-btns" + ((this.state.processing.isProcessing || this.state.processing.isFailed) ? " invisible" : "")}>
        <FileChooseButton onFileSelected={this.props.onFileSelected} className="try-photo">
          <div className="try-photo-container">
            <img src={assetUrl(`assets/img/photo.png`)} alt="." />
          </div>
          {i18n.t("try_another_photo")}
        </FileChooseButton>

        <button onClick={this.handleBackButtonClick} className="btn-back">
          <div className="btn-back-container">
            <img src={assetUrl(`assets/img/back.png`)} alt="." />
          </div>
          {i18n.t("collage__back")}
        </button>

        <button
          className="btn-done"
          dangerouslySetInnerHTML={{__html: i18n.t("collage__download")}}
          onClick={() => this.handleDownloadStartClick()}
        />
      </div>
    </div>
  }
}